<template>
	<div>
		<div class="gg-container">
			<div class="flex is-justify-space-between">
				<div class="search-container-fn-input">
					<label>问卷名称：</label>
					<el-input size="small" placeholder="请输入问卷名称" prefix-icon="el-icon-search" clearable v-model="keywords" @change="getTestPaper">
					</el-input>
				</div>
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
					@click="getTestPaper('restPage')"
				>
					<span>查找</span>
				</el-button>
				<div class="search-container-fn-input">
					<el-button icon="el-icon-plus" size="mini" type="primary" @click="toAdd">新增</el-button>
				</div>
			</div>
			<el-table style="margin-top: 20px;" :data="testPaperList" v-loading="loading">
				<el-table-column prop="id" label="ID" align="center" width="80">
				</el-table-column>
				<el-table-column prop="title" label="问卷名称" align="center" width="300">
				</el-table-column>
				<el-table-column prop="count" label="题数" align="center" width="200">
				</el-table-column>
				<el-table-column prop="agent_name" label="机构" align="center" width="200">
				</el-table-column>
				<el-table-column prop="depart_name" label="科室" align="center" width="200">
				</el-table-column>
				<el-table-column prop="status" label="状态" align="center" width="200">
					<template slot-scope="{row}">
						<el-switch @change="changeStatus($event,row)" v-model="row.status" class="switch"
							:active-value="1" :inactive-value="0" active-text="启用" inactive-text="禁用"
							active-color="#13ce66" inactive-color="#C8C8C8">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column prop="admin_name" label="创建人" align="center" width="200">
				</el-table-column>
				<el-table-column prop="created_at" label="创建时间" align="center" width="200">
				</el-table-column>
				<el-table-column label="操作" align="center" width="200" fixed="right">
					<template slot-scope="{ row }">
						<el-button style="padding: 7px 8px;" type="primary" @click="editTest(row)" size="mini"
							icon="el-icon-edit">编辑
						</el-button>
						<el-button style="padding: 7px 8px;margin-left: 5px;" type="danger" @click="deleteTest(row)"
							size="mini" icon="el-icon-delete">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
				@pagination="getTestPaper" />
		</div>
	</div>
</template>

<script>
	import Pagination from '@/components/Pagination';
	import {
		getTestPaper,
		deleteQuestion,
		updataStatus
	} from '@/api/cdm/cdm-healthFile.js'
	export default {
	  name: "examPaperLibrary",
		components: {
			Pagination,
		},
		data() {
			return {
				total: 0,
				keywords: '',
				loading: false,
				listQuery: {
					page: 1,
					limit: 50,
					importance: undefined,
					title: undefined,
					type: undefined,
					sort: '+id'
				},
				testPaperList: []
			}
		},
		created() {
			this.getTestPaper()
		},

		methods: {
			getTestPaper() {
				this.loading = true
				console.log("page``````")
				console.log(this.listQuery)
				let params = {}
				if (this.keywords.length) {
					params.keywords = this.keywords
				}
				params.page = this.listQuery.page
				params.page_size = this.listQuery.limit
				console.log("page``````", params)
				getTestPaper(params).then(res => {
					if (res.code == 200) {
						let _data = res.data
						this.total = _data.total
						this.listQuery.limit = Number(_data.per_page)
						this.testPaperList = _data.list
					}
					this.loading = false
				})
			},
			changeStatus(e, row) {
				let params = {
					status: e
				}
				updataStatus(row.id, params).then(res => {
				 if (res.code == 200) {
				  var msg = e == 1 ? '已启用' : '已禁用'
						return this.$message.success(msg)
					} else {
						return this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				})
				console.log(e)
			},
			deleteTest(row) {
				this.$confirm('是否删除问卷', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					console.log("row", row)
					deleteQuestion(row.id).then(res => {
						if (res.code == 200) {
							this.$message({
								message: '删除成功',
								type: 'success',
							})
							this.getTestPaper()
						}
					})
				})
			},
			editTest(row) {
				this.$router.push({
					path: '/healthFile/addTestPaper',
					query: {
						id: row.id,
						postType: 'edit'
					}
				})
			},
			toAdd() {
				this.$router.push({
					path: '/healthFile/addTestPaper'
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.header {
		display: flex;
		align-items: center;
		height: 26px;
		margin-bottom: 10px;

		i {
			width: 25px;
			height: 25px;
			background-color: #2632fb;
			color: white;
			line-height: 25px;
			text-align: center;
			border-radius: 4px;
			margin-right: 8px;
		}
	}

	/* switch按钮样式 */
	::v-deep.switch {
		.el-switch__label {
			position: absolute;
			display: none;
			color: #fff !important;
		}

		/*打开时文字位置设置*/
		.el-switch__label--right {
			z-index: 1;
		}

		/* 调整打开时文字的显示位子 */
		.el-switch__label--right span {
			margin-right: 11px;
		}

		/*关闭时文字位置设置*/
		.el-switch__label--left {
			z-index: 1;
		}

		/* 调整关闭时文字的显示位子 */
		.el-switch__label--left span {
			margin-left: 11px;
		}

		/*显示文字*/
		.el-switch__label.is-active {
			display: block;
		}

		/* 调整按钮的宽度 */
		.el-switch__core,
		.el-switch__label {
			width: 55px !important;
			margin: 0;
		}
	}
</style>
